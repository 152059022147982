import * as React from 'react';
import {
    classNames,
} from '../utils/';

import styles from './Button.module.scss';

export interface ButtonProps {
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
}

export const Button = (props: ButtonProps) => (
    <button
        onClick={props.onClick}
        className={classNames(
            props.className || '',
            styles.button,
        )}
    >
        {props.children}
    </button>
);

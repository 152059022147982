import * as type from 'runtypes';

export const StaticFragment = type.Record({
    fragmentType: type.Literal('static'),
    text: type.String,
});

export const DynamicFragment = type.Record({
    fragmentType: type.Literal('dynamic'),
    text: type.String,
});

export const Fragment = type.Union(StaticFragment, DynamicFragment);

export const StaticHashtag = type.Record({
    hashtagType: type.Literal('static'),
    text: type.String,
});

export const DynamicHashtag = type.Record({
    hashtagType: type.Literal('dynamic'),
    text: type.String,
});

export const Hashtag = type.Union(StaticHashtag, DynamicHashtag);

export const Message = type.Record({
    fragments: type.Array(Fragment),
    hashtags: type.Array(Hashtag),
});

export type Fragment = type.Static<typeof Fragment>;
export type Hashtag = type.Static<typeof Hashtag>;
export type Message = type.Static<typeof Message>;

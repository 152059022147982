export function lazy<A>(initializer: () => A): () => A {
    let initialized = false;
    let value: A;
    return () => {
        if (!initialized) {
            value = initializer();
            initialized = true;
        }
        return value;
    };
}

export function fromCallback(nodeCall: (callback: (err: any) => void) => void): Promise<void>;
export function fromCallback<T>(nodeCall: (callback: (err: any, result: T) => void) => void): Promise<T>;
export function fromCallback(nodeCall: (callback: (err: any, result?: any) => void) => void): Promise<any> {
    return new Promise((resolve, reject) => {
        nodeCall((err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}

export function retryPromise<A>(
    retryMaxCount: number,
    promiseFactory: () => Promise<A>,
): Promise<A> {
    return new Promise<A>((resolve, reject) => {
        promiseFactory().then(resolve).catch((err: Error) => {
            if (retryMaxCount > 0) {
                resolve(retryPromise(retryMaxCount - 1, promiseFactory));
            } else {
                reject(new Error(`Retries exhausted, last error: ${err.message}`));
            }
        });
    });
}

export function retryPromiseBackoff<A>(
    retryMaxCount: number,
    promiseFactory: () => Promise<A>,
    backoffTimeout: (retryNumber: number) => number = backoffLinear(100),
): Promise<A> {
    let currentRetry = 0;
    return retryPromise<A>(retryMaxCount, () => new Promise<A>((resolve, reject) => {
        const timeout = backoffTimeout(currentRetry++);
        setTimeout(() => promiseFactory().then(resolve, reject), timeout);
    }));
}

export function backoffLinear(backoffMs: number) {
    return (retryCount: number) => retryCount * backoffMs;
}

export function backoffExponential(backoffMs: number) {
    return (retryCount: number) =>
        retryCount > 0 ? backoffMs * Math.pow(2, retryCount - 1) : 0;
}

import * as R from 'ramda';
import * as React from 'react';
import {
    Result,
} from '../../common/types/';
import {
    parseMessage,
} from '../../common/message';
import {
    AppContext,
} from '../AppContext';
import {
    Head1,
    Text,
    ActionText,
    FadeOut,
    Button,
    MessageCard,
    Mote,
    SocialShareBar,
} from '../components';
import {
    whenAnalytics,
    classNames,
    randomInt,
} from '../utils';
import {
    submitFeedback,
} from '../api';

import { ReactComponent as ArrowNext } from '../images/ic_navi_next_arrow.svg';
import { ReactComponent as ArrowRestart } from '../images/ic_navi_restart.svg';

import userImage from '../images/img_user_1.png';
import thumbsDown from '../images/ic_thumbs_down.svg';
import thumbsUp from '../images/ic_thumbs_up.svg';
import styles from './ResultsPage.module.scss';

export interface ResultsPageProps {
    results: Result[];
}

export const ResultsPage = (props: ResultsPageProps) => {
    const { setPhase } = React.useContext(AppContext);
    const [resultIndex, setResultIndex] = React.useState(0);
    const [showProfiles, setShowProfiles] = React.useState(false);
    const [showOutro, setShowOutro] = React.useState(false);
    const [feedbackGiven, setFeedbackGiven] = React.useState(false);
    const {
        politician,
        messageText,
        targetVoter,
    } = props.results[resultIndex];
    return (
        <div className={styles.resultsPage}>
            <div className={styles.content}>
                <div className={styles.resultsScroll}>
                    <div className={classNames(styles.resultsContainer, showOutro && styles.showOutro)}>
                        <div className={styles.results}>
                            <header className={styles.titles}>
                                <Head1>Vaalilupaus</Head1>
                                <ActionText>{targetVoter ? `${targetVoter.name}:lle` : 'Sinulle'}</ActionText>
                            </header>
                            <main className={styles.card}>
                                <MessageCard politician={politician} message={parseMessage(messageText)}/>
                            </main>
                        </div>
                        <div className={styles.outro}>
                            <div className={styles.handMessage}>
                                <div className={styles.bigHand}>
                                    {renderMotes(15)}
                                </div>
                                <div className={styles.message}>
                                    <Text>Somessa tekemäsi valinnat vaikuttavat sinulle näkyviin vaalilupauksiin. <a href="https://yle.fi/uutiset/3-10703733" target="_blank">Lue lisää</a>.</Text>
                                </div>
                            </div>
                            <div className={styles.feedback}>
                                {feedbackGiven
                                    ? (<>
                                        <Text>Kiitos palautteestasi!</Text>
                                        <div className={styles.socialShare}>
                                            <SocialShareBar
                                                shareUrl={getShareUrl()}
                                                message={getShareMessage()}
                                                iconSize={'small'}
                                            />
                                        </div>
                                    </>)
                                    : (<>
                                        <Text>Piditkö testistä?</Text>
                                        <div className={styles.feedbackButtons}>
                                            <Button
                                                className={styles.feedbackButton}
                                                onClick={() => {
                                                    setFeedbackGiven(true);
                                                    submitFeedback(false);
                                                    whenAnalytics((analytics) => {
                                                        analytics.trackFeedbackThumbsDown();
                                                    });
                                                }}
                                            >
                                                <img src={thumbsDown} alt={'Thumbs down'}/>
                                            </Button>
                                            <Button
                                                className={styles.feedbackButton}
                                                onClick={() => {
                                                    setFeedbackGiven(true);
                                                    submitFeedback(true);
                                                    whenAnalytics((analytics) => {
                                                        analytics.trackFeedbackThumbsUp();
                                                    });
                                                }}
                                            >
                                                <img src={thumbsUp} alt={'Thumbs up'}/>
                                            </Button>
                                        </div>
                                    </>)}
                            </div>
                        </div>
                    </div>
                </div>
                <footer className={styles.compare}>
                    <FadeOut className={styles.flexCenter} in={showProfiles} timeout={300} mountOnEnter>
                        <div className={styles.compareProfiles} style={{
                            left: `calc(50% - ${resultIndex * 7 + 3.5}rem)`,
                        }}>
                            {props.results.map((r, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        styles.profile,
                                        resultIndex === index && !showOutro && styles.active,
                                    )}
                                    onClick={() => {
                                        setShowOutro(false);
                                        setResultIndex(index);
                                        whenAnalytics((analytics) => {
                                            const result = props.results[index];
                                            const voterId = result.targetVoter
                                                ? result.targetVoter._id
                                                : 'self';
                                            analytics.trackVoter(voterId);
                                        });
                                    }}>
                                    <img
                                        className={classNames(
                                            styles.profileImage,
                                            resultIndex === index && !showOutro && styles.active,
                                        )}
                                        src={r.targetVoter ? r.targetVoter.imageUrl : userImage}
                                        alt={r.targetVoter ? r.targetVoter.name : 'Sinä'}/>
                                    <ActionText className={styles.profileName}>
                                        {r.targetVoter ? r.targetVoter.name : 'Sinä'}
                                    </ActionText>
                                </div>
                            ))}
                            <div
                                key={'continue'}
                                className={classNames(
                                    styles.profile,
                                    styles.active,
                                    showOutro ? styles.restart : styles.next
                                )}
                                onClick={() => {
                                    if (showOutro) {
                                        setPhase('intro');
                                        whenAnalytics((analytics) => {
                                            analytics.trackRestart();
                                        });
                                    } else {
                                        setShowOutro(true);
                                        whenAnalytics((analytics) => {
                                            analytics.trackOutro();
                                        });
                                    }
                                }}>
                                {
                                    showOutro
                                        ? <ArrowRestart className={styles.profileImage}/>
                                        : <ArrowNext className={styles.profileImage}/>
                                }
                                <ActionText className={styles.profileName}>
                                    {showOutro ? 'Alkuun' : <span>&nbsp;</span>}
                                </ActionText>
                            </div>
                        </div>
                    </FadeOut>
                    <FadeOut in={!showProfiles} timeout={300} unmountOnExit>
                        <div className={styles.compareCta}>
                            <Button
                                className={styles.compareButton}
                                onClick={() => {
                                    setShowProfiles(true);
                                    whenAnalytics((analytics) => {
                                        analytics.trackCompare();
                                    });
                                }}
                            >
                                <ActionText>Vertaa lupauksia</ActionText>
                            </Button>
                        </div>
                    </FadeOut>
                </footer>
            </div>
        </div>
    );
};

function renderMotes(count: number) {
    const colors = [
        '#04b2e7', '#ffffff', '#f88d86', '#f8e164',
        '#f36148', '#ec2099', '#d5b8c7', '#f2de63',
    ];
    return <>
        {R.range(0, count).map((index) => (
            <div key={index} className={styles.mote} style={{
                left: `${randomInt(5, 95)}%`,
                top: `${randomInt(0, 60)}%`,
            }}>
                <Mote
                    size={randomInt(5, 15)}
                    color={colors[randomInt(0, colors.length)]}
                />
            </div>
        ))}
    </>;
}

function getShareUrl() {
    const ogUrlMeta = document.querySelector<HTMLMetaElement>('meta[property="og:url"]');
    return ogUrlMeta ? ogUrlMeta.content : window.location.href;
}

function getShareMessage() {
    return 'Millaisen vaalilupauksen sinä saisit? Kokeile miten mainoksia räätälöidään somessa juuri sinulle. #vaalit2019';
}

import * as React from 'react';
import {
    TwitterQuestion,
    TwitterAnswer,
} from '../../common/types/';
import {
    AppContext,
} from '../AppContext';
import {
    Head2,
    ActionText,
    Button,
} from '.';
import {
    classNames,
} from '../utils';

import { ReactComponent as CommentIcon } from '../images/pink_bubble.svg';
import { ReactComponent as RetweetIcon } from '../images/ic_retweet.svg';
import { ReactComponent as LikeIcon } from '../images/ic_heart.svg';
import profileIcon from '../images/img_sote_profile.png';
import styles from './Twitter.module.scss';

export const Twitter = (question: TwitterQuestion) => {
    const { answerQuestion } = React.useContext(AppContext);
    return (
        <div className={styles.twitter}>
            <main className={styles.content}>
                <div className={styles.tweet}>
                    <header>
                        <img className={styles.profileIcon} src={profileIcon} alt={'Profile icon'}/>
                        <ActionText>{question.handle}</ActionText>
                    </header>
                    <main>
                        <Head2>{question.tweetText}</Head2>
                    </main>
                    <footer>
                        <div className={styles.stats1}>
                            <div>
                                <ActionText className={styles.strong}>12</ActionText>{' '}
                                <ActionText className={styles.muted}>Retweets</ActionText>
                            </div>
                            <div>
                                <ActionText className={styles.strong}>3</ActionText>{' '}
                                <ActionText className={styles.muted}>Likes</ActionText>
                            </div>
                        </div>
                        <div className={styles.stats2}>
                            <div>
                                <CommentIcon className={classNames(styles.miniIcon, styles.comment)}/>
                                <ActionText className={styles.muted}>23</ActionText>
                            </div>
                            <div>
                                <RetweetIcon className={classNames(styles.miniIcon, styles.retweet)}/>
                                <ActionText className={styles.muted}>12</ActionText>
                            </div>
                            <div>
                                <LikeIcon className={classNames(styles.miniIcon, styles.like)}/>
                                <ActionText className={styles.muted}>3</ActionText>
                            </div>
                        </div>
                    </footer>
                </div>
            </main>
            <footer className={styles.buttons}>
                <Button
                    className={styles.twitterButton}
                    onClick={() => { answerQuestion(twitterAnswer(question, false)); }}
                >
                    <ActionText>Ohita</ActionText>
                </Button>
                <Button
                    className={styles.twitterButton}
                    onClick={() => { answerQuestion(twitterAnswer(question, true)); }}
                >
                    <LikeIcon className={styles.buttonIcon}/>
                </Button>
            </footer>
        </div>
    );
};

function twitterAnswer(question: TwitterQuestion, retweeted: boolean): TwitterAnswer {
    return {
        mediaType: question.mediaType,
        questionId: question._id,
        retweeted,
    };
}

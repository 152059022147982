import * as R from 'ramda';
import {
    Message,
    Fragment,
    Hashtag,
} from './messageTypes';

const messageRegex = /^([^#]+)(#?.*)$/i;

export function parseMessage(messageString: string): Message {
    const groups = messageRegex.exec(messageString);

    if (!groups || groups.length < 3) {
        throw new Error(`Failed to parse message: ${messageString}`);
    }

    const fragmentsString = groups[1];
    const hashtagsString = groups[2];

    const fragments = parseFragments(fragmentsString);
    const hashtags = parseHashtags(hashtagsString);

    return {
        fragments,
        hashtags,
    };
}

function parseFragments(fragmentsString: string): Fragment[] {

    const unterminatedDynamicFragment = () => {
        throw new Error(`Unterminated dynamic fragment in string: ${fragmentsString}`);
    };
    const nestedDynamicFragments = () => {
        throw new Error(`Nested dynamic fragments in string: ${fragmentsString}`);
    };
    const unmatchedDynamicFragment = () => {
        throw new Error(`Unmatched dynamic fragment in string: ${fragmentsString}`);
    };

    const dynamicFragment = (text: string): Fragment => ({ fragmentType: 'dynamic', text });
    const staticFragment = (text: string): Fragment => ({ fragmentType: 'static', text });

    const upsertDynamic = R.cond([
        [R.isEmpty, (fs) => (s: string) => R.append(dynamicFragment(s), fs)],
        [R.T, (fs) => Fragment.match(
            (_) => (s: string) => R.append(dynamicFragment(s), fs),
            (d) => (s: string) => R.append(dynamicFragment(`${d.text}${s}`), R.dropLast(1, fs)),
        )(R.last<Fragment>(fs)!)]
    ]);

    const upsertStatic = R.cond([
        [R.isEmpty, (fs) => (s: string) => R.append(staticFragment(s), fs)],
        [R.T, (fs) => Fragment.match(
            (f) => (s: string) => R.append(staticFragment(`${f.text}${s}`), R.dropLast(1, fs)),
            (_) => (s: string) => R.append(staticFragment(s), fs),
        )(R.last<Fragment>(fs)!)]
    ]);

    const parseDynamic = (fragments: Fragment[]) => R.cond([
        [R.equals('{'), () => nestedDynamicFragments()],
        [R.equals('}'), () => ({ dynamic: false, fragments })],
        [R.T, (s: string) => ({ dynamic: true, fragments: upsertDynamic(fragments)(s) })],
    ]);

    const parseStatic = (fragments: Fragment[]) => R.cond([
        [R.equals('{'), () => ({ dynamic: true, fragments })],
        [R.equals('}'), () => unmatchedDynamicFragment()],
        [R.T, (s: string) => ({ dynamic: false, fragments: upsertStatic(fragments)(s) })],
    ]);

    const m = R.reduce(({ dynamic, fragments }, s) =>
        R.cond([
            [R.equals(true), () => parseDynamic(fragments)(s)],
            [R.equals(false), () => parseStatic(fragments)(s)],
        ])(dynamic),
        {
            dynamic: false,
            fragments: [],
        },
        fragmentsString.split(''));

    if (m.dynamic) {
        unterminatedDynamicFragment();
    }

    return m.fragments;
}

function parseHashtags(hashtagString: string): Hashtag[] {

    const malformedHashtags = () => {
        throw new Error(`Malformed hashtags in string: ${hashtagString}`);
    };

    const dynamicHashtag = (text: string): Hashtag => ({ hashtagType: 'dynamic', text });
    const staticHashtag = (text: string): Hashtag => ({ hashtagType: 'static', text });

    return R.map(R.cond([
        [R.test(/^{[^}]+}$/), (s) => dynamicHashtag(s.substring(1, s.length - 1))],
        [R.test(/^[^{}]+$/), (s) => staticHashtag(s)],
        [R.T, () => malformedHashtags()]
    ]), R.reject(R.isEmpty, hashtagString.split('#')));
}

import { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import * as type from 'runtypes';
import {
    Question,
    Answer,
    Result,
    NewFeedback,
} from '../common/types/';
import {
    retryPromiseBackoff,
} from '../common/utils';

function getRequestConfig(): AxiosRequestConfig {
    const xsrfMeta = document.querySelector<HTMLMetaElement>('meta[name=x-xsrf-token]');
    return {
        headers: {
            'content-type': 'application/json',
            'x-xsrf-token': xsrfMeta ? xsrfMeta.content : '',
        },
    };
}

export function getQuestions(): Promise<Question[]> {
    return retryPromiseBackoff(2, () =>
        axios.get('/api/v1/questions', getRequestConfig()).then((res) =>
            type.Array(Question).check(res.data)));
}

export function submitAnswers(answers: Answer[]): Promise<Result[]> {
    return retryPromiseBackoff(2, () =>
        axios.post('/api/v1/analyze', answers, getRequestConfig()).then((res) =>
            type.Array(Result).check(res.data)));
}

export function submitFeedback(thumbsUp: boolean): Promise<any> {
    const feedback: NewFeedback = {
        sessionId: '',
        userAgent: navigator.userAgent,
        thumbsUp,
    };
    return retryPromiseBackoff(2, () =>
        axios.post('/api/v1/feedback', feedback, getRequestConfig()));
}

import * as React from 'react';
import {
    InstantMessageQuestion,
    InstantMessageAnswer,
} from '../../common/types/';
import {
    AppContext,
} from '../AppContext';
import {
    Text,
    Head2,
    ActionText,
    Button,
} from '.';

import fatherInLaw from '../images/img_ukki.png';
import styles from './InstantMessage.module.scss';

export const InstantMessage = (question: InstantMessageQuestion) => {
    const { answerQuestion } = React.useContext(AppContext);
    return (
        <div className={styles.instantMessage}>
            <main className={styles.content}>
                <div className={styles.message}>
                    <header>
                        <div className={styles.profileBar}>
                            <img
                                className={styles.profileIcon}
                                src={fatherInLaw}
                                alt={'Grandpa'}/>
                            <ActionText>Ukki</ActionText>
                        </div>
                        <div className={styles.triangle}></div>
                    </header>
                    <main style={{ backgroundImage: `url('${question.imageUrl}')` }}>
                        <div className={styles.backdrop}></div>
                        <Text className={styles.hl}>HL</Text>
                        <Head2 className={styles.caption}>{question.imageCaption}</Head2>
                    </main>
                    <footer>
                        <Head2>{question.messageText}</Head2>
                    </footer>
                </div>
            </main>
            <footer className={styles.buttons}>
                <Button
                    className={styles.instantMessageButton}
                    onClick={() => { answerQuestion(instantMessageAnswer(question, true)); }}
                >
                    <ActionText>{question.leftResponse}</ActionText>
                </Button>
                <Button
                    className={styles.instantMessageButton}
                    onClick={() => { answerQuestion(instantMessageAnswer(question, false)); }}
                >
                    <ActionText>{question.rightResponse}</ActionText>
                </Button>
            </footer>
        </div>
    );
};

function instantMessageAnswer(question: InstantMessageQuestion, isLeft: boolean): InstantMessageAnswer {
    return {
        mediaType: question.mediaType,
        questionId: question._id,
        isLeft,
    };
}

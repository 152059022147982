import * as React from 'react';
import { SvgProgressBar } from '.';

import { ReactComponent as Circle } from '../images/progress_circle.svg';

export interface ProgressCircleProps {
    currentStep: number;
    maximumStep: number;
}

export const ProgressCircle = (props: ProgressCircleProps) => {
    const currentFraction = (props.currentStep / props.maximumStep) || 0;
    const options = {
        duration: 300,
        easing: 'easeOutCubic',
    };

    return <>
        <Circle style={{
            width: '100%',
            height: '100%',
            fill: 'none',
            transform: 'rotateY(180deg) rotateZ(90deg)',
        }}/>
        <SvgProgressBar
            pathSelector={`#progress-circle #path1`}
            options={options}
            initialAnimate={true}
            progress={currentFraction}
        />
    </>;
};

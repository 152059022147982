import * as React from 'react';
import {
    InstagramQuestionOption,
    InstagramQuestion,
    InstagramAnswer,
} from '../../common/types/';
import {
    classNames,
} from '../utils';
import {
    AppContext,
} from '../AppContext';
import {
    ActionText,
    Button,
} from '.';

import { ReactComponent as HeartIcon } from '../images/ic_heart.svg';
import styles from './Instagram.module.scss';

export const Instagram = (question: InstagramQuestion) => {
    const { answerQuestion } = React.useContext(AppContext);
    return (
        <div className={styles.instagram}>
            <main className={styles.content}>
                <div className={styles.split}>
                    {renderSplitSide(question.left, styles.leftSide)}
                    {renderSplitSide(question.right, styles.rightSide)}
                </div>
            </main>
            <footer className={styles.buttons}>
                <Button
                    className={styles.instagramButton}
                    onClick={() => { answerQuestion(instagramAnswer(question, true)); }}
                >
                    <HeartIcon className={styles.heartIcon}/>
                </Button>
                <Button
                    className={styles.instagramButton}
                    onClick={() => { answerQuestion(instagramAnswer(question, false)); }}
                >
                    <HeartIcon className={styles.heartIcon}/>
                </Button>
            </footer>
        </div>
    );
};

function renderSplitSide(option: InstagramQuestionOption, sideClass: string) {
    return (
        <div className={classNames(styles.splitSide, sideClass)}>
            <div
                className={classNames(styles.splitImage, sideClass)}
                style={{ backgroundImage: `url('${option.imageUrl}')` }}>
            </div>
            <div
                className={classNames(styles.backdrop, sideClass)}>
            </div>
            <footer className={styles.tags}>
                {option.tags.map((tag, i) => (
                    <div key={i}><ActionText>#{tag}</ActionText></div>
                ))}
            </footer>
        </div>
    );
}

function instagramAnswer(question: InstagramQuestion, isLeft: boolean): InstagramAnswer {
    return {
        mediaType: question.mediaType,
        questionId: question._id,
        isLeft,
    };
}

import * as type from 'runtypes';
import {
    ObjectId,
} from './types';

export const TwitterAnswer = type.Record({
    mediaType: type.Literal('twitter'),
    questionId: ObjectId,
    retweeted: type.Boolean,
});

export const InstagramAnswer = type.Record({
    mediaType: type.Literal('instagram'),
    questionId: ObjectId,
    isLeft: type.Boolean,
});

export const InstantMessageAnswer = type.Record({
    mediaType: type.Literal('instantMessage'),
    questionId: ObjectId,
    isLeft: type.Boolean,
});

export const NewsAnswer = type.Record({
    mediaType: type.Literal('news'),
    questionId: ObjectId,
    thumbsUp: type.Boolean,
});

export const AdAnswer = type.Record({
    mediaType: type.Literal('ad'),
    questionId: ObjectId,
    isLeft: type.Boolean,
});

export const Answer = type.Union(
    TwitterAnswer,
    InstagramAnswer,
    InstantMessageAnswer,
    NewsAnswer,
    AdAnswer,
);

export const NewAnswers = type.Record({
    sessionId: type.String,
    answers: type.Array(Answer),
});

export const Answers = NewAnswers.And(type.Record({
    _id: ObjectId,
}));

export type TwitterAnswer = type.Static<typeof TwitterAnswer>;
export type InstagramAnswer = type.Static<typeof InstagramAnswer>;
export type InstantMessageAnswer = type.Static<typeof InstantMessageAnswer>;
export type NewsAnswer = type.Static<typeof NewsAnswer>;
export type AdAnswer = type.Static<typeof AdAnswer>;
export type Answer = type.Static<typeof Answer>;
export type NewAnswers = type.Static<typeof NewAnswers>;
export type Answers = type.Static<typeof Answers>;

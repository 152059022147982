import * as React from 'react';
import * as ProgressBar from 'progressbar.js';

export class SvgProgressBar extends React.Component<any, any> {

    static defaultProps: any = {
        options: {},
        progress: 0,
        initialAnimate: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            path: undefined,
        };
    }

    render() {
        return <></>;
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.state.path === undefined) {
            this._create(nextProps);
        }
        this._animateProgress(nextProps.progress);
    }

    componentDidMount() {
        this._create(this.props);
    }

    _create(props: any, oldProps?: any) {
        if (this.state.path !== undefined) {
            return;
        }
        (this.state as any).path = new ProgressBar.Path(
            props.pathSelector,
            props.options,
        );

        if (props.initialAnimate) {
            if (oldProps) {
                this._setProgress(oldProps.progress);
            }

            this._animateProgress(props.progress);
        } else {
            this._setProgress(props.progress);
        }
    }

    _animateProgress(progress: any) {
        this.state.path.animate(progress);
    }

    _setProgress(progress: any) {
        this.state.path.set(progress);
    }
}

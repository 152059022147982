import * as React from 'react';
import {
    Transition,
} from 'react-transition-group';
import {
    classNames,
} from '../utils/';

import styles from './FadeOut.module.scss';

export interface FadeOutProps {
    in: boolean;
    timeout: number;
    children: React.ReactNode;
    mountOnEnter?: boolean;
    unmountOnExit?: boolean;
    className?: string;
}

export const FadeOut = (props: FadeOutProps) => (
    <Transition
        in={props.in}
        timeout={props.timeout}
        mountOnEnter={props.mountOnEnter}
        unmountOnExit={props.unmountOnExit}
    >
        {(state) =>
            <div
                className={classNames(props.className, styles.fade, styles[state])}
                style={{
                    transition: `opacity ${props.timeout}ms linear`,
                }}
            >
                {props.children}
            </div>
        }
    </Transition>
);

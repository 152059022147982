import * as React from 'react';
import {
    classNames,
} from '../utils';

import styles from './typography.module.scss';

export interface TypographyProps {
    className?: string;
    children: React.ReactNode;
}

export const Head1 = ({ className, children }: TypographyProps) => (
    <span className={classNames(className, styles.head1)}>{children}</span>
);

export const Head2 = ({ className, children }: TypographyProps) => (
    <span className={classNames(className, styles.head2)}>{children}</span>
);

export const ActionText = ({ className, children }: TypographyProps) => (
    <span className={classNames(className, styles.actiontext)}>{children}</span>
);

export const Text = ({ className, children }: TypographyProps) => (
    <span className={classNames(className, styles.text)}>{children}</span>
);

import * as React from 'react';
import {
    Facebook,
    Twitter,
    Whatsapp,
} from 'react-social-sharing';
import styles from './SocialShareBar.module.scss';

export type SocialIconSize =
    | 'small'
    | 'medium'
    | 'big';

interface SocialShareBarProps {
    shareUrl: string;
    message: string;
    iconSize?: SocialIconSize;
}

export const SocialShareBar = ({ shareUrl, message, iconSize }: SocialShareBarProps) => {
    const small = iconSize === 'small';
    const medium = iconSize === 'medium';
    const big = iconSize === 'big';
    return (
        <div className={styles.socialShareBar}>
            <Facebook link={shareUrl} {...{ message, small, medium , big }}/>
            <Twitter link={shareUrl} {...{ message, small, medium , big }}/>
            <Whatsapp link={shareUrl} {...{ message, small, medium , big }}/>
        </div>
    );
};

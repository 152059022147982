import * as React from 'react';
import {
    randomInt,
} from '../utils';

import styles from './Mote.module.scss';

export interface MoteProps {
    size?: number;
    color?: string;
    pulseDuration?: number;
    pulseDelay?: number;
}

export const Mote = (props: MoteProps) => {
    const size = props.size || randomInt(10, 20);
    const color = props.color || `rgb(${randomInt(0, 255)}, ${randomInt(0, 255)}, ${randomInt(0, 255)})`;
    const durationMs = props.pulseDuration || randomInt(2500, 5000) + 100 * size;
    const delayMs = props.pulseDelay || randomInt(0, 2500);
    return (
        <div className={styles.mote} style={{
            width: size,
            height: size,
            backgroundColor: color,
            animationDuration: `${durationMs}ms`,
            animationDelay: `${delayMs}ms`,
        }}/>
    );
};

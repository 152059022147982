import * as R from 'ramda';
import * as React from 'react';
import {
    Transition,
} from 'react-transition-group';
import {
    AppContext,
} from '../AppContext';
import {
    Head1,
    Text,
    SvgProgressBar,
} from '../components/';
import {
    classNames,
} from '../utils';

import { ReactComponent as AlgorithmFace } from '../images/algorithm_face.svg';
import styles from './AlgorithmPage.module.scss';

export const AlgorithmPage = () => {
    const {
        algorithmTimeout,
        setPhase,
    } = React.useContext(AppContext);

    React.useEffect(() => {
        setTimeout(() => {
            setPhase('results');
        }, algorithmTimeout);
    }, []);

    return (
        <div className={styles.algorithmPage}>
            <main className={styles.faceContainer}>
                {renderAlgorithmFace(algorithmTimeout)}
                {renderKeywords(algorithmTimeout)}
            </main>
            <footer className={styles.messages}>
                <div
                    className={classNames(styles.message, styles.generatingProfile)}
                    style={{ animationDelay: `${algorithmTimeout/2}ms` }}
                >
                    <Head1>Rakennetaan profiilia</Head1>
                </div>
                <div
                    className={classNames(styles.message, styles.generatingPromise)}
                    style={{ animationDelay: `${algorithmTimeout/2}ms` }}
                >
                    <Head1>Luodaan vaalilupausta</Head1>
                </div>
            </footer>
        </div>
    );
};

function renderAlgorithmFace(algorithmTimeout: number) {
    const options = {
        duration: algorithmTimeout / 2,
        easing: 'easeOutCubic',
        from: {
            opacity: 0.1,
        },
        to: {
            opacity: 1.0,
        },
        step: (state: any, { path }: any) => {
            path.setAttribute('stroke-opacity', state.opacity - 0.2);
        },
    };
    const startDelayMs = algorithmTimeout * (1/8);
    return (
        <Transition timeout={startDelayMs} in appear>
            {(state) => (
                state === 'entered'
                    ? <>
                        <AlgorithmFace className={styles.algorithmFace}/>
                        {R.range(0, 7).map((index) => (
                            <SvgProgressBar
                                key={index}
                                pathSelector={`#algorithm-face #path${index + 1}`}
                                options={options}
                                initialAnimate={true}
                                progress={1.0}
                            />
                        ))}
                    </>
                    : <div/>
            )}
        </Transition>
    )
}

function renderKeywords(algorithmTimeout: number) {
    return (
        <>
        {R.range(0, 20).map((index) => {
            const topPct = Math.floor(Math.random() * 20) * 3 + 10;
            const startDelayMs = algorithmTimeout * (5/8);
            const animationDelayMs = startDelayMs + (index / 20) * algorithmTimeout / 4;
            const animationDurationMs = algorithmTimeout / 6;
            const keyword = getKeyword(index);
            return (
                <div
                    key={index}
                    className={styles.promiseKeyword}
                    style={{
                        top: `${topPct}%`,
                        animationDuration: `${animationDurationMs}ms`,
                        animationDelay: `${animationDelayMs}ms`,
                    }}
                >
                    <Text>{keyword}</Text>
                </div>
            );
        })}
        </>
    );
}

function getKeyword(index: number): string {
    const keywords = [
        'isänmaa',
        'sinivalkoinen',
        'välittäminen',
        'talouskuntoon',
        'eläkeikä',
        'opiskelijat',
        'vanhustenhuolto',
        'terveydenhuolto',
        'ilmastonmuutos',
        'tulevaisuus',
        'lapsemme',
        'verotus',
        'maaseutu',
        'globalisaatio',
        'maailma',
        'yrittäminen',
        'vapaus valita',
        'tekoja',
        'suvaitsevaisuus',
        'valinnat',
        'ihmisarvo',
        'sukupolvet',
    ];
    return keywords[index % keywords.length];
}

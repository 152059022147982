import * as R from 'ramda';
import * as React from 'react';
import {
    AppContext,
} from '../AppContext';
import {
    Button,
    Head1,
    Text,
    ActionText,
    Mote,
} from '../components/';
import {
    randomInt,
} from '../utils';

import algoFace from '../images/algorithm_face.png';
import blueBubble from '../images/blue_bubble.svg';
import pinkBubble from '../images/pink_bubble.svg';
import styles from './IntroPage.module.scss';

export const IntroPage = () => {
    const { setPhase } = React.useContext(AppContext);
    return (
        <div className={styles.introPage}>
            <div className={styles.betaRibbon}>
                <ActionText>{' Beta '.repeat(5)}</ActionText>
            </div>
            <div className={styles.content}>
                <header className={styles.header}>
                    <img className={styles.algoFace} src={algoFace} alt={'Algorithm'}/>
                    <img className={styles.blueBubble} src={blueBubble} alt={'Blue bubble'}/>
                    <img className={styles.pinkBubble} src={pinkBubble} alt={'Pink bubble'}/>
                    {renderMotes(15)}
                </header>
                <main className={styles.body}>
                    <Head1>Sama ehdokas.<br/>Eri lupaus.</Head1>
                    <Text className={styles.messageCta}>
                        Vaalimainos suositellaan somessa
                        {' '}
                        <a href="https://yle.fi/uutiset/3-10703733" target="_blank">
                            käytöksesi perusteella
                        </a>.
                        {' '}
                        Kokeile, kuinka personointi toimii.
                    </Text>
                    <div>
                        <Button
                            className={styles.startButton}
                            onClick={() => { setPhase('instructions'); }}
                        >
                            <ActionText>Aloita testi</ActionText>
                        </Button>
                    </div>
                </main>
            </div>
        </div>
    );
};

function renderMotes(count: number) {
    const colors = [
        '#04b2e7', '#ffffff', '#f88d86', '#f8e164',
        '#f36148', '#ec2099', '#d5b8c7', '#f2de63',
    ];
    return <>
        {R.range(0, count).map((index) => (
            <div key={index} className={styles.mote} style={{
                left: `${randomInt(25, 75)}%`,
                top: `${randomInt(10, 90)}%`,
            }}>
                <Mote
                    size={randomInt(5, 15)}
                    color={colors[randomInt(0, colors.length)]}
                />
            </div>
        ))}
    </>;
}

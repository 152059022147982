import * as React from 'react';
import {
    NewsQuestion,
    NewsAnswer,
} from '../../common/types/';
import {
    AppContext,
} from '../AppContext';
import {
    ActionText,
    Head2,
    Button,
} from '.';

import thumbsDown from '../images/ic_thumbs_down.svg';
import thumbsUp from '../images/ic_thumbs_up.svg';
import styles from './News.module.scss';

export const News = (question: NewsQuestion) => {
    const { answerQuestion } = React.useContext(AppContext);
    return (
        <div className={styles.news}>
            <main className={styles.content}>
                <div className={styles.article}>
                    <header style={{
                        backgroundImage: `url('${question.imageUrl}')`,
                    }}/>
                    <main>
                        <ActionText className={styles.headlineLabel}>Uutinen</ActionText>
                        <Head2>{question.headline}</Head2>
                    </main>
                </div>
            </main>
            <footer className={styles.buttons}>
                <Button
                    className={styles.newsButton}
                    onClick={() => { answerQuestion(newsAnswer(question, false)); }}
                >
                    <img className={styles.newsButtonIcon} src={thumbsDown} alt={'Thumbs down'}/>
                </Button>
                <Button
                    className={styles.newsButton}
                    onClick={() => { answerQuestion(newsAnswer(question, true)); }}
                >
                    <img className={styles.newsButtonIcon} src={thumbsUp} alt={'Thumbs up'}/>
                </Button>
            </footer>
        </div>
    );
};

function newsAnswer(question: NewsQuestion, thumbsUp: boolean): NewsAnswer {
    return {
        mediaType: question.mediaType,
        questionId: question._id,
        thumbsUp,
    };
}

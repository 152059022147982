import * as React from 'react';
import {
    AdQuestionOption,
    AdQuestion,
    AdAnswer,
} from '../../common/types/';
import {
    AppContext,
} from '../AppContext';
import {
    Text,
    Head2,
    ActionText,
    Button,
} from '.';
import {
    classNames,
} from '../utils';

import styles from './Ad.module.scss';

export const Ad = (question: AdQuestion) => {
    const { answerQuestion } = React.useContext(AppContext);
    return (
        <div className={styles.ad}>
            <main className={styles.content}>
                <div className={styles.split}>
                    {renderSplitSide(question.left, styles.leftSide)}
                    {renderSplitSide(question.right, styles.rightSide)}
                </div>
            </main>
            <footer className={styles.buttons}>
                <Button
                    className={styles.adButton}
                    onClick={() => { answerQuestion(adAnswer(question, true)); }}
                >
                    <ActionText>{question.left.actionText}</ActionText>
                </Button>
                <Button
                    className={styles.adButton}
                    onClick={() => { answerQuestion(adAnswer(question, false)); }}
                >
                    <ActionText>{question.right.actionText}</ActionText>
                </Button>
            </footer>
        </div>
    );
}

function renderSplitSide(option: AdQuestionOption, sideClass: string) {
    return (
        <div className={classNames(styles.splitSide, sideClass)}>
            <div
                className={classNames(styles.splitImage, sideClass)}
                style={{ backgroundImage: `url('${option.imageUrl}')` }}>
            </div>
            <header className={styles.adLabel}>
                <Head2 className={styles.allcaps}>Mainos</Head2>
            </header>
            <footer className={styles.copyText}>
                <Text>{option.copyText}</Text>
            </footer>
        </div>
    );
}

function adAnswer(question: AdQuestion, isLeft: boolean): AdAnswer {
    return {
        mediaType: question.mediaType,
        questionId: question._id,
        isLeft,
    };
}

import * as type from 'runtypes';
import { isWebUri } from 'valid-url';

const isValidUrl = (url: string) => Boolean(isWebUri(url));

export const ObjectId = type.String;

export const Url = type.String.withConstraint(isValidUrl);

export const ResultVector = type.Tuple(type.Number, type.Number, type.Number);

export const PositionVector = type.Tuple(type.Number, type.Number, type.Number);

export const PoliticalAgenda = type.Record({
    _id: ObjectId,
    name: type.String,
    position: PositionVector,
});

export const PoliticalIdeology = type.Record({
    _id: ObjectId,
    name: type.String,
    position: PositionVector,
});

export const PoliticalMessage = type.Record({
    _id: ObjectId,
    textTemplate: type.String,
    agenda: PoliticalAgenda,
    ideology: PoliticalIdeology,
});

export const Politician = type.Record({
    _id: ObjectId,
    name: type.String,
    imageUrl: Url,
    ideology: PoliticalIdeology,
});

export const VoterStereotype = type.Record({
    _id: ObjectId,
    name: type.String,
    imageUrl: Url,
    ideology: PoliticalIdeology,
});

export const Profile = type.Record({
    _id: ObjectId,
    name: type.String,
    imageUrl: Url,
});

export const Result = type.Record({
    politician: Profile,
    messageText: type.String,
    targetVoter: Profile.Or(type.Undefined),
});

export const NewFeedback = type.Record({
    sessionId: type.String,
    thumbsUp: type.Boolean,
    userAgent: type.String,
});

export const Feedback = NewFeedback.And(type.Record({
    _id: ObjectId,
}));

export type ResultVector = type.Static<typeof ResultVector>;
export type PositionVector = type.Static<typeof PositionVector>;
export type PoliticalAgenda = type.Static<typeof PoliticalAgenda>;
export type PoliticalIdeology = type.Static<typeof PoliticalIdeology>;
export type PoliticalMessage = type.Static<typeof PoliticalMessage>;
export type Politician = type.Static<typeof Politician>;
export type VoterStereotype = type.Static<typeof VoterStereotype>;
export type Profile = type.Static<typeof Profile>;
export type Result = type.Static<typeof Result>;
export type NewFeedback = type.Static<typeof NewFeedback>;
export type Feedback = type.Static<typeof Feedback>;

import * as R from 'ramda';
import * as React from 'react';
import {
    Head2,
} from '.';

import styles from './EffectText.module.scss';

const effectDurationMs = 500;
const effectCharCount = 3;

export interface EffectTextProps {
    className?: string;
    text: string;
}

export const EffectText = ({ className, text }: EffectTextProps) => {
    const characters = text.split('');
    const slotFractionMs = effectDurationMs / characters.length;
    const slotDurationMs = slotFractionMs * 3;
    const charFractionMs = slotDurationMs / effectCharCount;
    const charDurationMs = charFractionMs * 3;
    return (
        <Head2 className={className}>
            {characters.map((textChar, i) => (
                <span key={`${text}-${i}`} className={styles.charSlot}>
                    <span
                        className={styles.textChar}
                        style={{
                            animationDuration: `${charFractionMs}ms`,
                            animationDelay: `${slotFractionMs * i + charFractionMs * effectCharCount}ms`,
                        }}
                    >
                        {textChar}
                    </span>
                    {R.range(0, effectCharCount).map((_, j) => (
                        <span
                            key={`${text}-${j}`}
                            className={styles.effectChar}
                            style={{
                                animationDuration: `${charDurationMs}ms`,
                                animationDelay: `${slotFractionMs * i + charFractionMs * j}ms`,
                            }}
                        >
                            {characters[(i + effectCharCount - j) % characters.length]}
                        </span>
                    ))}
                </span>
            ))}
        </Head2>
    );
};

import * as React from 'react';
import {
    Answer,
} from '../common/types/';

export type AppPhase =
    | 'intro'
    | 'instructions'
    | 'questions'
    | 'algorithm'
    | 'results';

export interface AppContext {
    phaseTimeout: number;
    instructionsTimeout: number;
    algorithmTimeout: number;
    setPhase: (phase: AppPhase) => void;
    answerQuestion: (answer: Answer) => void;
}

export const initialContext: AppContext = {
    phaseTimeout: 300,
    instructionsTimeout: 2000,
    algorithmTimeout: 8000,
    setPhase: (phase: AppPhase) => undefined,
    answerQuestion: (answer: Answer) => undefined,
};

export const AppContext = React.createContext<AppContext>(initialContext);

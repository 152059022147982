export function whenDefined<A>(globalName: string, callback: (x: A) => void) {
    const windowAsAny = window as any;
    if (typeof windowAsAny[globalName] !== 'undefined') {
        callback(windowAsAny[globalName]);
    } else {
        addCallback(globalName, callback);
        defineProperty(globalName);
    }
}

function addCallback(globalName: string, callback: (x: any) => void): void {
    getCallbacksForName(globalName).push(callback);
}

function triggerCallbacks(globalName: string, value: any): void {
    getCallbacksForName(globalName).forEach((callback: (x: any) => void) => {
        callback(value);
    });
}

function getCallbacksForName(globalName: string): ((x: any) => void)[] {
    const windowAsAny = window as any;
    const callbacksKey = '__whenDefinedCallbacks';
    const allCallbacks = windowAsAny[callbacksKey] || (windowAsAny[callbacksKey] = {});
    const nameCallbacks = allCallbacks[globalName] || (allCallbacks[globalName] = []);
    return nameCallbacks;
}

function defineProperty(globalName: string) {
    const internalName = `_${globalName}`;
    Object.defineProperty(window, globalName, {
        configurable: true,
        enumerable: true,
        get: function() {
            return this ? this[internalName] : undefined;
        },
        set: function(value) {
            if (this) {
                this[internalName] = value;
                triggerCallbacks(globalName, value);
            }
        }
    });
}

import * as React from 'react';
import {
    AppContext,
} from '../AppContext';
import {
    Head1,
} from '../components';

import hand  from '../images/ic_hand.svg';
import crossMark from '../images/ic_x_mark.svg';
import checkMark from '../images/ic_v_mark.svg';
import styles from './InstructionsPage.module.scss';

export const InstructionsPage = () => {
    const {
        instructionsTimeout,
        setPhase,
    } = React.useContext(AppContext);

    React.useEffect(() => {
        setTimeout(() => {
            setPhase('questions');
        }, instructionsTimeout);
    }, []);

    return (
        <div className={styles.instructionsPage}>
            <header className={styles.header}>
                <div className={styles.marks}>
                    <img className={styles.crossMark} src={crossMark} alt={'Cross mark'}/>
                    <img className={styles.checkMark} src={checkMark} alt={'Check mark'}/>
                </div>
                <img className={styles.hand} src={hand} alt={'Hand'}/>
            </header>
            <main className={styles.body}>
                <Head1>Kumman<br/>valitset?</Head1>
            </main>
        </div>
    );
};

import * as type from 'runtypes';
import {
    ObjectId,
    ResultVector,
    Url,
} from './types';

export const TwitterQuestion = type.Record({
    _id: ObjectId,
    mediaType: type.Literal('twitter'),
    handle: type.String,
    tweetText: type.String,
});

export const TwitterQuestionWithResults = TwitterQuestion.And(type.Record({
    notweetResult: ResultVector,
    retweetResult: ResultVector,
}));

export const InstagramQuestionOption = type.Record({
    imageUrl: Url,
    tags: type.Array(type.String),
});

export const InstagramQuestion = type.Record({
    _id: ObjectId,
    mediaType: type.Literal('instagram'),
    left: InstagramQuestionOption,
    right: InstagramQuestionOption,
});

export const InstagramQuestionWithResults = InstagramQuestion.And(type.Record({
    leftResult: ResultVector,
    rightResult: ResultVector,
}));

export const InstantMessageQuestion = type.Record({
    _id: ObjectId,
    mediaType: type.Literal('instantMessage'),
    imageUrl: Url,
    imageCaption: type.String,
    messageText: type.String,
    leftResponse: type.String,
    rightResponse: type.String,
});

export const InstantMessageQuestionWithResults = InstantMessageQuestion.And(type.Record({
    leftResult: ResultVector,
    rightResult: ResultVector,
}));

export const NewsQuestion = type.Record({
    _id: ObjectId,
    mediaType: type.Literal('news'),
    imageUrl: Url,
    headline: type.String,
});

export const NewsQuestionWithResults = NewsQuestion.And(type.Record({
    thumbsUpResult: ResultVector,
    thumbsDownResult: ResultVector,
}));

export const AdQuestionOption = type.Record({
    imageUrl: Url,
    copyText: type.String,
    actionText: type.String,
});

export const AdQuestion = type.Record({
    _id: ObjectId,
    mediaType: type.Literal('ad'),
    left: AdQuestionOption,
    right: AdQuestionOption,
});

export const AdQuestionWithResults = AdQuestion.And(type.Record({
    leftResult: ResultVector,
    rightResult: ResultVector,
}));

export const Question = type.Union(
    TwitterQuestion,
    InstagramQuestion,
    InstantMessageQuestion,
    NewsQuestion,
    AdQuestion,
);

export const QuestionWithResults = type.Union(
    TwitterQuestionWithResults,
    InstagramQuestionWithResults,
    InstantMessageQuestionWithResults,
    NewsQuestionWithResults,
    AdQuestionWithResults,
);

export type TwitterQuestion = type.Static<typeof TwitterQuestion>;
export type TwitterQuestionWithResults = type.Static<typeof TwitterQuestionWithResults>;
export type InstagramQuestionOption = type.Static<typeof InstagramQuestionOption>;
export type InstagramQuestion = type.Static<typeof InstagramQuestion>;
export type InstagramQuestionWithResults = type.Static<typeof InstagramQuestionWithResults>;
export type InstantMessageQuestion = type.Static<typeof InstantMessageQuestion>;
export type InstantMessageQuestionWithResults = type.Static<typeof InstantMessageQuestionWithResults>;
export type NewsQuestion = type.Static<typeof NewsQuestion>;
export type NewsQuestionWithResults = type.Static<typeof NewsQuestionWithResults>;
export type AdQuestionOption = type.Static<typeof AdQuestionOption>;
export type AdQuestion = type.Static<typeof AdQuestion>;
export type AdQuestionWithResults = type.Static<typeof AdQuestionWithResults>;
export type Question = type.Static<typeof Question>;
export type QuestionWithResults = type.Static<typeof QuestionWithResults>;

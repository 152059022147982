import * as R from 'ramda';
import * as React from 'react';
import {
    Question,
    Answer,
    Result,
} from '../common/types/';
import {
    AppPhase,
    AppContext,
    initialContext,
} from './AppContext';
import {
    IntroPage,
    InstructionsPage,
    QuestionsPage,
    AlgorithmPage,
    ResultsPage,
} from './pages/';
import {
    FadeOut,
} from './components/';
import {
    getQuestions,
    submitAnswers,
} from './api';
import {
    whenAnalytics,
} from './utils';

import styles from './App.module.scss';

interface AppState {
    phase: AppPhase;
    questionIndex: number;
    questions: Question[];
    answers: Answer[];
    results: Result[];
}

class App extends React.Component<{}, AppState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            phase: 'intro',
            questionIndex: 0,
            questions: [],
            answers: [],
            results: [],
        };
        this.setPhase = this.setPhase.bind(this);
        this.answerQuestion = this.answerQuestion.bind(this);
        this.getQuestions = this.getQuestions.bind(this);
        this.submitAnswers = this.submitAnswers.bind(this);
    }

    async componentDidMount() {
        whenAnalytics((analytics) => {
            analytics.trackPhase(this.state.phase);
        });
        await this.getQuestions();
    }

    render() {
        const context: AppContext = {
            ...initialContext,
            setPhase: this.setPhase,
            answerQuestion: this.answerQuestion,
        };
        return (
            <AppContext.Provider value={context}>
                <main className={styles.app}>
                    <FadeOut
                        in={this.state.phase === 'results'}
                        timeout={context.phaseTimeout}
                        unmountOnExit
                    >
                        <div className={styles.page}>
                            <ResultsPage results={this.state.results}/>
                        </div>
                    </FadeOut>
                    <FadeOut
                        in={this.state.phase === 'algorithm'}
                        timeout={context.phaseTimeout}
                        unmountOnExit
                    >
                        <div className={styles.page}>
                            <AlgorithmPage/>
                        </div>
                    </FadeOut>
                    <FadeOut
                        in={this.state.phase === 'questions'}
                        timeout={context.phaseTimeout}
                        unmountOnExit
                    >
                        <div className={styles.page}>
                            <QuestionsPage {
                                ...{
                                    questionIndex: this.state.questionIndex,
                                    questions: this.state.questions,
                                }
                            }/>
                        </div>
                    </FadeOut>
                    <FadeOut
                        in={this.state.phase === 'instructions'}
                        timeout={context.phaseTimeout}
                        unmountOnExit
                    >
                        <div className={styles.page}>
                            <InstructionsPage/>
                        </div>
                    </FadeOut>
                    <FadeOut
                        in={this.state.phase === 'intro'}
                        timeout={context.phaseTimeout}
                        unmountOnExit
                    >
                        <div className={styles.page}>
                            <IntroPage/>
                        </div>
                    </FadeOut>
                </main>
            </AppContext.Provider>
        );
    }

    setPhase(phase: AppPhase) {
        const isIntro = phase === 'intro';
        const questionIndex = isIntro ? 0 : this.state.questionIndex;
        const answers = isIntro ? [] : this.state.answers;

        whenAnalytics((analytics) => {
            analytics.trackPhase(phase);

            if (phase === 'questions') {
                analytics.trackQuestion(this.state.questions[0]._id);
            }
        });

        this.setState({
            phase,
            questionIndex,
            answers,
        });
    }

    answerQuestion(answer: Answer) {
        const answers = R.append(answer, this.state.answers);
        const isLastAnswer = answers.length === this.state.questions.length;
        const phase = isLastAnswer ? 'algorithm' : this.state.phase;
        const questionIndex = this.state.questionIndex + (isLastAnswer ? 0 : 1);

        whenAnalytics((analytics) => {
            analytics.trackAnswer(answer.questionId, Answer.match(
                (tA) => tA.retweeted,
                (igA) => igA.isLeft,
                (imA) => imA.isLeft,
                (nA) => nA.thumbsUp,
                (aA) => aA.isLeft,
            )(answer));

            if (isLastAnswer) {
                analytics.trackPhase(phase);
            } else {
                analytics.trackQuestion(this.state.questions[questionIndex]._id);
            }
        });

        this.setState({
            phase,
            questionIndex,
            answers,
        }, async () => {
            if (isLastAnswer) {
                await this.submitAnswers();
            }
        });
    }

    async getQuestions() {
        try {
            const questions = await getQuestions();
            this.setState({
                questions,
            });
        } catch (err) {
            console.error(err); // tslint:disable-line:no-console
        }
    }

    async submitAnswers() {
        try {
            const results = await submitAnswers(this.state.answers);
            whenAnalytics((analytics) => {
                analytics.trackResult(results[0].politician._id);
            });
            this.setState({
                results,
            });
        } catch (err) {
            console.error(err); // tslint:disable-line:no-console
        }
    }
}

export default App;

import * as React from 'react';
import {
    Question,
} from '../../common/types';
import {
    Head2,
    ProgressCircle,
    Twitter,
    Instagram,
    InstantMessage,
    News,
    Ad,
} from '../components/';

import styles from './QuestionsPage.module.scss';

export interface QuestionsPageProps {
    questionIndex: number;
    questions: Question[];
}

const renderQuestion = Question.match(
    twitterQ => <Twitter {...twitterQ}/>,
    instagramQ => <Instagram {...instagramQ}/>,
    instantMessageQ => <InstantMessage {...instantMessageQ}/>,
    newsQ => <News {...newsQ}/>,
    adQ => <Ad {...adQ}/>,
);

export const QuestionsPage = ({ questionIndex, questions }: QuestionsPageProps) => {
    return (
        <div className={styles.questionsPage}>
            <div className={styles.content}>
                <header className={styles.header}>
                    <div className={styles.progress}>
                        <ProgressCircle currentStep={questionIndex + 0.5} maximumStep={questions.length}/>
                    </div>
                    <Head2 className={styles.fraction}>
                        {questionIndex + 1} / {questions.length}
                    </Head2>
                </header>
                <main className={styles.body}>
                    <div className={styles.questions} style={{
                        left: `${-100 * questionIndex}%`,
                    }}>
                        {questions.map((q, i) => (
                            <div key={i} className={styles.question}>
                                {renderQuestion(q)}
                            </div>
                        ))}
                    </div>
                </main>
            </div>
        </div>
    );
};

import * as React from 'react';
import {
    Profile,
} from '../../common/types/';
import {
    Message,
    Fragment,
    Hashtag,
} from '../../common/message';
import {
    Head2,
    ActionText,
    EffectText,
} from '.';

import styles from './MessageCard.module.scss';

export interface MessageCardProps {
    politician: Profile;
    message: Message;
}

export const MessageCard = ({ politician, message }: MessageCardProps) => (
    <div className={styles.messageCard}>
        <header className={styles.politician}>
            <img
                className={styles.profileImage}
                src={politician.imageUrl}
                alt={politician.name}/>
            <div className={styles.profileName}>
                <ActionText>{politician.name}</ActionText>
                <ActionText className={styles.profileLabel}>
                    Ehdokas
                </ActionText>
            </div>
        </header>
        <main className={styles.message}>
            <div className={styles.fragments}>
                {message.fragments.map((f, i) => renderFragment(f, i))}
            </div>
            <div className={styles.hashtags}>
                {message.hashtags.map((h, i) => renderHashtag(h, i))}
            </div>
        </main>
    </div>
);

function renderFragment(fragment: Fragment, index: number) {
    return Fragment.match(
        (s) => (
            <div key={`fragment-${index}`}>
                <Head2>{s.text}</Head2>
            </div>
        ),
        (d) => (
            <div key={`fragment-${index}`} className={styles.dynamic}>
                <EffectText text={d.text}/>
            </div>
        ),
    )(fragment);
}

function renderHashtag(hashtag: Hashtag, index: number) {
    return Hashtag.match(
        (s) => (
            <div key={`hashtag-${index}`}>
                <Head2 className={styles.hashtag}>#{s.text}</Head2>
            </div>
        ),
        (d) => (
            <div key={`hashtag-${index}`} className={styles.dynamic}>
                <Head2 className={styles.hashtag}>#</Head2>
                <EffectText className={styles.hashtag} text={d.text}/>
            </div>
        ),
    )(hashtag);
}

import {
    snake,
} from 'change-case';
import {
    AppPhase,
} from '../AppContext';
import {
    whenDefined,
} from '.';

export interface YleAnalytics {
    trackPage: (pageName: string) => void;
    trackEvent: (eventName: string) => void;
}

export interface AppAnalytics {
    trackPhase: (phaseName: AppPhase) => void;
    trackQuestion: (questionId: string) => void;
    trackAnswer: (questionId: string, value: any) => void;
    trackResult: (politicianId: string) => void;
    trackVoter: (voterId: string) => void;
    trackCompare: () => void;
    trackOutro: () => void;
    trackFeedbackThumbsUp: () => void;
    trackFeedbackThumbsDown: () => void;
    trackRestart: () => void;
}

export function whenAnalytics(f: (analytics: AppAnalytics) => void) {
    whenDefined<YleAnalytics>('yleAnalytics', (analytics) => {
        try {
            f({
                trackPhase(phaseName: string) {
                    analytics.trackPage(`5mvm.phase.${snake(phaseName)}`)
                },
                trackQuestion(questionId: string) {
                    analytics.trackEvent(`5mvm.question.${questionId}`);
                },
                trackAnswer(questionId: string, value: any) {
                    analytics.trackEvent(`5mvm.answer.${questionId}.${encodeURIComponent(value)}`);
                },
                trackResult(politicianId: string) {
                    analytics.trackEvent(`5mvm.result.${politicianId}`);
                },
                trackVoter(voterId: string) {
                    analytics.trackEvent(`5mvm.voter.${voterId}`);
                },
                trackCompare() {
                    analytics.trackEvent('5mvm.compare');
                },
                trackOutro() {
                    analytics.trackEvent('5mvm.outro');
                },
                trackFeedbackThumbsUp() {
                    analytics.trackEvent('5mvm.feedback.thumbs_up');
                },
                trackFeedbackThumbsDown() {
                    analytics.trackEvent('5mvm.feedback.thumbs_down');
                },
                trackRestart() {
                    analytics.trackEvent('5mvm.restart');
                },
            });
        } catch (err) {
            console.error(err); // tslint:disable-line:no-console
        }
    });
}
